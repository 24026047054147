import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DEFAULT_PAGE_PARAMS, FILTER_TABS_CONFIG, QUOTES_TABLE_COLUMNS } from './quotes-dashboard.config';
import { Subject, debounceTime, distinctUntilChanged, finalize, take, takeUntil } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { ShareQuoteDialogComponent } from '../../shared/components/share-quote-dialog/share-quote-dialog.component';
import { SearchComponent } from '../../shared/components/search/search.component';
import { StatusComponent } from '../../shared/components/status/status.component';
import { CustomPaginatorIntl } from '../../shared/utils/custom-paginator-intl';
import { ApplicationGridResponse, PageParams, Quote } from '../../shared/models/quotes.interface';
import { PAYMENT_TYPE_LABEL } from '../../shared/enums/paymentType.enum';
import { NavigationService } from '../../shared/services/navigation.service';
import { AuthService } from '../../shared/services/auth.service';
import { QuotesRepository } from '../../shared/repositories/quotes.repository';
import { getHttpParams } from '../../shared/utils/create-http-params';
import { FullWidthScrollDirective } from '../../shared/directives/full-width-scroll.directive';
import {FiltersTab} from "../../shared/models/common.interface";

@Component({
  selector: 'app-quotes-dashboard',
  standalone: true,
  imports: [
    SearchComponent,
    StatusComponent,
    ShareQuoteDialogComponent,
    MatButton,
    MatIcon,
    CommonModule,
    MatButtonModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    FullWidthScrollDirective,
  ],
  templateUrl: './quotes-dashboard.component.html',
  styleUrl: './quotes-dashboard.component.scss',
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }],
})
export class QuotesDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('search') searchComponent: SearchComponent;

  @HostListener('click', ['$event'])
  closeDialog(event: PointerEvent): void {
    this.closeDialogByOverlay(event);
  }

  filterTabs = FILTER_TABS_CONFIG;
  activeTab: FiltersTab = this.filterTabs[0];
  pageParams: PageParams = DEFAULT_PAGE_PARAMS;
  displayedColumns = QUOTES_TABLE_COLUMNS;
  pageSizeOptions = [10, 25, 50];
  quotesTotalCount: number;
  data: Quote[] = [];
  dataSource = new MatTableDataSource(this.data);

  paymentTypeLabel = PAYMENT_TYPE_LABEL;

  isShowShareLinkDialog: boolean = false;
  isInProgress: boolean = false;
  shareLink: string;
  agentModeLink: string;
  searchValue: string = '';
  destroy$ = new Subject<void>();

  constructor(
    private navService: NavigationService,
    private authService: AuthService,
    private quotesRepository: QuotesRepository
  ) {}

  ngOnInit() {
    this.navService.defaultNavigateByRole(this.authService.getRole());
    this.setSharedLink();
    this.setFilter(this.activeTab);
    this.setData(this.pageParams);
  }

  ngAfterViewInit(): void {
    this.paginator.selectConfig.panelClass = 'select-dropdown-paginator';
    this.onSearchQueryChange();
  }

  onSearchQueryChange(): void {
    this.searchComponent.searchControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((searchValue: string) => {
        this.searchValue = searchValue;
        this.pageParams = {
          ...this.pageParams,
          PageIndex: 0,
          ByName: searchValue,
        };

        this.setData(this.pageParams);
      });
  }

  setData(pageParams: PageParams): void {
    const params = getHttpParams(pageParams);
    this.isInProgress = true;
    this.quotesRepository
      .getApplicationData(params)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isInProgress = false))
      )
      .subscribe((res: ApplicationGridResponse) => {
        this.quotesTotalCount = res.total;
        this.dataSource = new MatTableDataSource(res.data);
      });
  }

  setSharedLink(): void {
    this.quotesRepository
      .getSharedLink()
      .pipe(take(1))
      .subscribe(link => {
        this.shareLink = link;
        this.agentModeLink = this.shareLink + '&isAgentMode=true';
      });
  }

  setFilter(tab: FiltersTab): void {
    if (tab.filterType) {
      this.pageParams = {
        ...DEFAULT_PAGE_PARAMS,
        PageSize: this.pageParams.PageSize,
        applicationStatusType: tab.filterType,
        ByName: this.searchValue,
      };
    } else {
      this.pageParams = {
        ...DEFAULT_PAGE_PARAMS,
        PageSize: this.pageParams.PageSize,
        ByName: this.searchValue,
      };
    }
  }

  onPaginatorChange($event: PageEvent): void {
    this.pageParams.PageIndex = $event.pageIndex;
    this.pageParams.PageSize = $event.pageSize;
    this.setData(this.pageParams);
  }

  shareLinkClick(): void {
    this.isShowShareLinkDialog = !this.isShowShareLinkDialog;
  }

  closeShareQuoteDialog(): void {
    this.isShowShareLinkDialog = false;
  }

  closeDialogByOverlay(event: PointerEvent): void {
    const target: HTMLElement = event.target as HTMLElement;
    const conditionToClose = target.classList.contains('overlay-dialog');

    if (this.isShowShareLinkDialog && conditionToClose) {
      this.closeShareQuoteDialog();
    }
  }

  applyFilter(currentTab: FiltersTab): void {
    this.activeTab = currentTab;
    this.setFilter(currentTab);
    this.setData(this.pageParams);
  }

  resendLink(element: Quote): void {
    this.isInProgress = true;
    this.quotesRepository
      .resendApplicationLinkByMail(element.id)
      .pipe(
        take(1),
        finalize(() => (this.isInProgress = false))
      )
      .subscribe();
  }

  openQuote(quote: Quote) {
    const url = `${this.shareLink}&applicationId=${quote.id}&isAgentMode=true'${quote.listBillId ? '&ListBill=' + quote.listBillId : ''}`;
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
