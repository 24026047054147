<div class="wrap-dialog-content">
  <div class="d-flex align-items-center justify-content-between pb-3">
    <span class="dialog-title">{{data.title}}</span>
    <button type="button" mat-icon-button class="close-btn" (click)="onCanceled()">
      <mat-icon class="action-menu-icon">close</mat-icon>
    </button>
  </div>
  <div>
    <form [formGroup]="form">
      <div class="mb-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Group Name</mat-label>
          <input
            matInput
            formControlName="name"/>
          @if (form.controls.name.errors?.required) {
            <mat-error>Name is required</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="mb-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Number of pay periods per year</mat-label>
          <input
            matInput
            type="number"
            min="1"
            formControlName="nPayPeriod"/>
          @if (form.controls.nPayPeriod.errors?.required) {
            <mat-error>Number of pay periods is required</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="mb-2 d-flex flex-column flex-md-row align-items-center justify-content-between">
        <mat-form-field class="date-field" appearance="outline">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepickerFilter]="startDateFilter"
            [matDatepicker]="startDatePicker"
            formControlName="startDate" />

          @if (form.controls.startDate.errors?.required) {
            <mat-error>Start Date is required.</mat-error>
          }
          <mat-datepicker-toggle matIconSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="date-field" appearance="outline">
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepickerFilter]="endDateFilter"
            [matDatepicker]="endDatePicker"
            formControlName="endDate" />

          @if (form.controls.endDate.errors?.required) {
            <mat-error>Start Date is required.</mat-error>
          }
          <mat-datepicker-toggle matIconSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="mb-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Group size</mat-label>
          <input
            type="number"
            min="0"
            matInput
            formControlName="groupSize"/>
          @if (form.controls.groupSize.errors?.required) {
            <mat-error>Group size is required</mat-error>
          }
        </mat-form-field>
      </div>
      @if (data.type === 'edit') {
        <div class="mb-2">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Loomis number</mat-label>
            <input
              type="number"
              matInput
              formControlName="loomisNum"/>
          </mat-form-field>
        </div>
      }
    </form>
  </div>
  <div class="dialog-footer">
    <div mat-dialog-actions class="dialog-actions d-flex align-items-center justify-content-end p-0">
      <button mat-button color="primary" class="btn-sm" (click)="onCanceled()">Cancel</button>
      <button mat-raised-button class="btn-sm" color="primary" (click)="submit()">{{data.type === 'create' ? 'Create' : 'Edit'}} Group</button>
    </div>
  </div>
</div>
