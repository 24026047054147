export enum StatusesEnum {
  Quoting = 1,
  Enrollment = 2,
  Submitted = 3,
}

export const STATUSES_LABEL = {
  [StatusesEnum.Quoting]: 'Quoting',
  [StatusesEnum.Enrollment]: 'Enrollment',
  [StatusesEnum.Submitted]: 'Submitted',
};
