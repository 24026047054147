import { HttpParams } from '@angular/common/http';

export function getHttpParams(params: { [key: string]: any }): HttpParams {
  let httpParams: HttpParams = new HttpParams({});

  if (!params) {
    return httpParams;
  }

  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.forEach(val => {
        if (val || val === 0) {
          httpParams = httpParams.append(key, val);
        }
      });
    } else {
      if (value || value === 0 || value === false) {
        httpParams = httpParams.set(key, value);
      }
    }
  }

  return httpParams;
}
