import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthRepository {
  constructor(private http: HttpClient) {}

  login(paramsData): Observable<{ token: string }> {
    const url = `${environment.apiUrl}/api/v1/Auth/login`;
    return this.http.post<{ token: string }>(url, paramsData);
  }

  logout() {
    const url = `${environment.apiUrl}/api/v1/Auth/logout`;
    return this.http.get(url);
  }

  forgotPassword(paramsData) {
    const url = `${environment.apiUrl}/api/v1/Auth/forgotPassword`;
    return this.http.get(url, { params: paramsData });
  }

  resetPassword(paramsData) {
    const url = `${environment.apiUrl}/api/v1/Auth/resetPassword`;
    return this.http.post(url, paramsData);
  }
}
