import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { routesPaths } from '../../app.routes';
import { LoginHelpInfoComponent } from '../../shared/components/login-help-info/login-help-info.component';
import { NavigationService } from '../../shared/services/navigation.service';
import { AuthRepository } from '../../shared/repositories/auth.repository';
import { regexPatterns } from '../../shared/utils/regex';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    MatButton,
    MatCheckbox,
    MatError,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatSuffix,
    ReactiveFormsModule,
    LoginHelpInfoComponent,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  resetLinkSubmitted: boolean;
  constructor(
    private fb: FormBuilder,
    private navService: NavigationService,
    private authRepository: AuthRepository
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.forgotPasswordForm = this.fb.group({
      email: [null, [Validators.required, Validators.pattern(regexPatterns.email)]],
    });
  }

  onBackToLoginClick() {
    this.navService.navigate(routesPaths.login);
  }

  onSubmitClick() {
    if (this.forgotPasswordForm.valid) {
      this.authRepository.forgotPassword(this.forgotPasswordForm.value).subscribe(() => {
        this.resetLinkSubmitted = true;
      });
    } else {
      this.forgotPasswordForm.markAllAsTouched();
    }
  }
}
