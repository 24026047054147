<div class="login-content h-100 w-100">
  <h1 class="page-title text-center mb-0">Welcome to Flex Benefits!</h1>
  <div class="d-flex justify-content-center">
    <div class="login-form-container form-container">
      <div class="d-flex justify-content-center">
        <form class="login-form form" [formGroup]="loginForm">
          <div class="form-title mb-5">Login to Agent portal</div>
          <mat-form-field class="w-100 mb-4" appearance="outline" (input)="clearCredentialsError('email')">
            <mat-label>Enter your email</mat-label>
            <input matInput formControlName="email" />
            <mat-icon matSuffix>email</mat-icon>
            @if (loginForm.controls.email.errors?.required) {
              <mat-error>Email is required.</mat-error>
            }
            @if (loginForm.controls.email.errors?.pattern) {
              <mat-error>Email format does not match.</mat-error>
            }
          </mat-form-field>
          <mat-form-field
            class="w-100"
            appearance="outline"
            (input)="clearCredentialsError('password')"
            [ngClass]="credsError ? 'mb-4' : 'mb-2'">
            <mat-label>Enter your password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" />
            <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            @if (loginForm.controls.password.errors?.required) {
              <mat-error>Password is required.</mat-error>
            }
            @if (loginForm.controls.password.errors?.credsError) {
              <mat-error>
                <span>Wrong Email or Password.</span>
                <br />
                <span>Try again or use <span class="fw-bold">Forgot Password</span> procedure.</span>
              </mat-error>
            }
          </mat-form-field>
          <div class="ps-2 mb-4 d-flex align-items-center justify-content-end">
            <a
              href="javascript:void(0)"
              class="forgot-link text-primary-blue text-decoration-none"
              (click)="onForgotLinkClick()"
              >First Time User or Forgot Password?</a
            >
          </div>
          <div class="mb-4">
            <button
              [disabled]="loginForm.controls.password.errors?.credsError"
              mat-raised-button
              color="primary"
              class="w-100"
              type="button"
              (click)="onLoginClick()">
              Login
            </button>
          </div>
          @if (showForgotLink) {
            <app-login-help-info></app-login-help-info>
          }
        </form>
      </div>
    </div>
  </div>
</div>
