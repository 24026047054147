<header class="header">
  <div class="header-logo-container d-flex align-items-center justify-content-between">
    <a class="logo-link">
      <img class="header-logo" src="assets/img/logo.png" alt="logo" />
    </a>
    @if (isAuthorised) {
      @if (isAgent) {
        <div class="nav d-none d-md-flex">
          <div class="nav-item">
            <a class="nav-link" routerLink="/quotes-dashboard" routerLinkActive="active">
              Quotes
            </a>
          </div>
          <div class="nav-item">
            <a class="nav-link" routerLink="/bills-dashboard" routerLinkActive="active">
              Groups
            </a>
          </div>
        </div>
      }
      <div class="d-flex nav align-items-center section-actions">
        <button mat-button class="logout-btn d-flex btn-sm align-items-center" (click)="onBrokerResourcesClick()">
          <span>Broker Resources</span>
          <mat-icon>open_in_new</mat-icon>
        </button>
        <button mat-button class="logout-btn d-flex btn-sm align-items-center" (click)="onLogoutClick()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </div>
    }
  </div>
  @if (isAuthorised) {
    @if (isAgent) {
      <div class="nav d-flex d-md-none pt-3">
        <div class="nav-item">
          <a class="nav-link" routerLink="/quotes-dashboard" routerLinkActive="active">
            Quotes
          </a>
        </div>
        <div class="nav-item">
          <a class="nav-link" routerLink="/bills-dashboard" routerLinkActive="active">
            Groups
          </a>
        </div>
      </div>
    }
  }
</header>
