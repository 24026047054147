import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { routesPaths } from '../../app.routes';
import { LoginHelpInfoComponent } from '../../shared/components/login-help-info/login-help-info.component';
import { NavigationService } from '../../shared/services/navigation.service';
import { AuthService } from '../../shared/services/auth.service';
import { AuthRepository } from '../../shared/repositories/auth.repository';
import { regexPatterns } from '../../shared/utils/regex';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckbox,
    MatButton,
    MatIcon,
    LoginHelpInfoComponent,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  credsError: boolean;
  showForgotLink: boolean;
  hide = true;
  constructor(
    private fb: FormBuilder,
    private navService: NavigationService,
    private authService: AuthService,
    private authRepository: AuthRepository
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.pattern(regexPatterns.email)]],
      password: [null, [Validators.required]],
    });
  }

  onLoginClick() {
    if (this.loginForm.valid) {
      this.authRepository.login(this.loginForm.value).subscribe(
        res => {
          this.authService.setToken(res.token);
          this.navService.defaultNavigateByRole(this.authService.getRole());
        },
        err => {
          if (err.status === 401) {
            this.setCredentialsError();
          }
        }
      );
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  setCredentialsError() {
    this.credsError = true;
    this.showForgotLink = true;
    this.loginForm.get('email')?.setErrors({ credsError: true });
    this.loginForm.get('password')?.setErrors({ credsError: true });
    this.loginForm.markAllAsTouched();
  }

  clearCredentialsError(fieldName: string) {
    if (this.credsError && this.loginForm.get(fieldName)?.value) {
      this.credsError = false;
      this.loginForm.get('email')?.setErrors(null);
      this.loginForm.get('password')?.setErrors(null);
      this.loginForm.markAllAsTouched();
    }
  }

  onForgotLinkClick() {
    this.navService.navigate(routesPaths.forgotPassword);
  }
}
