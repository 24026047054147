<div class="page-content-container h-100 mt-4">
  <div class="top-content">
    <div class="w-100">
      <div class="d-flex flex-column flex-lg-row justify-content-between align-items-md-center pb-3">
        <div class="sub-title ps-0 ps-lg-2 mb-4 mb-lg-0">Accounts manager</div>
        <div>
          <div class="d-flex flex-column align-items-center flex-lg-row section-actions">
            <button
              mat-stroked-button
              color="primary"
              (click)="onDownloadTemplateClick()"
              class="download-btn btn-lg d-flex align-items-center me-lg-4 mb-3 mb-lg-0"
              type="button">
              <mat-icon>download</mat-icon>
              Download Template (.csv)
            </button>
            <button
              mat-raised-button
              color="primary"
              class="upload-btn btn-lg d-flex align-items-center"
              type="button"
              (click)="onUploadClick()">
              <mat-icon>upload</mat-icon>
              <span>Upload New Agents (.csv)</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-content">
    <div class="scrollable-area">
      <div class="w-100">
        <div class="section-filters">
          <nav mat-tab-nav-bar [tabPanel]="tabPanel">
            @for (tab of filterTabs; track tab.label) {
              <a mat-tab-link (click)="applyFilter(tab)" [active]="activeTab === tab">
                <mat-icon>{{ tab.icon }}</mat-icon>
                <span class="tab-label">{{ tab.label }}</span>
              </a>
            }
          </nav>
        </div>
        <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
      </div>

      <div class="w-100">
        <div class="separating-line" appFullWidthScroll [scrollableElement]="scrollableElement"></div>
        <div class="search-wrapper d-flex justify-content-between flex-column flex-md-row">
          <app-search #search></app-search>
          <button
            mat-raised-button
            color="primary"
            class="create-btn btn-lg"
            type="button"
            (click)="createAgent()">
            <span>Create Agent</span>
          </button>
        </div>

        <div #scrollableElement>
          <table mat-table [dataSource]="dataSource" class="app-table">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex flex-column">
                  <span>{{ element.name }}</span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="npn">
              <th mat-header-cell *matHeaderCellDef>NPN</th>
              <td mat-cell *matCellDef="let element">{{ element.npn }}</td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>Email</th>
              <td mat-cell *matCellDef="let element">{{ element.email }}</td>
            </ng-container>

            <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef>Phone</th>
              <td mat-cell *matCellDef="let element">{{ element?.phone }}</td>
            </ng-container>

            <ng-container matColumnDef="distributionName">
              <th mat-header-cell *matHeaderCellDef>Distribution name</th>
              <td mat-cell *matCellDef="let element">
                {{ element.distributionName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="licensedInStates">
              <th mat-header-cell *matHeaderCellDef>States</th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex align-items-center flex-wrap py-1">
                  @for (state of element.licensedInStates; track state; let last = $last) {
                    <div class="me-1">
                      <span>{{ state }}</span>
                      @if (!last) {
                        <span>,</span>
                      }
                    </div>
                  }
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="isActive">
              <th mat-header-cell *matHeaderCellDef>Is Active</th>
              <td mat-cell *matCellDef="let element">
                @if (element.isActive) {
                  <mat-icon class="text-success">check_circle_outline</mat-icon>
                } @else {
                  <mat-icon class="text-error">cancel</mat-icon>
                }
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let element">
                <button type="button" class="action-menu-btn" mat-icon-button [matMenuTriggerFor]="action_menu">
                  <mat-icon class="action-menu-icon">more_horizontal</mat-icon>
                </button>

                <mat-menu #action_menu="matMenu" xPosition="before">
                  <button mat-menu-item (click)="editAgent(element)">
                    <span>Edit</span>
                  </button>
                </mat-menu>
              </td>
            </ng-container>

            <tr class="mat-row row-no-data" *matNoDataRow>
              <td class="mat-cell cell-no-data" [attr.colspan]="displayedColumns.length">
                <div class="d-flex flex-column wrap-cell-no-data">
                  @if (!isInProgress) {
                    <img class="no-data-img" src="assets/img/no_data.png" alt="no-data" />
                    @if (searchComponent?.searchControl?.value) {
                      <span class="no-results-title">No results found</span>
                      <span class="no-results-subtitle">
                        Try to adjust your search or filter options to find what you are looking for.
                      </span>
                    } @else {
                      <span class="no-results-title">There are no records yet</span>
                    }
                  }
                </div>
              </td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-row"></tr>
          </table>
        </div>
      </div>
    </div>

    <div class="w-100">
      <div class="pagination-container d-flex align-items-center justify-content-end">
        <mat-paginator
          #paginator
          class="app-paginator"
          [pageSizeOptions]="pageSizeOptions"
          [length]="agentsTotalCount"
          [pageIndex]="pageParams.PageIndex"
          [showFirstLastButtons]="true"
          (page)="onPaginatorChange($event)">
        </mat-paginator>
      </div>
    </div>

    @if (showUploadModal) {
      <div class="drop-zone-overlay" id="dropZoneOverlay" (click)="onUploadOverlayClick($event)">
        <div class="drop-zone">
          <button class="cancel-btn" (click)="hideUploadModal()" mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
          <input
            class="file-input"
            #uploadInput
            type="file"
            id="uploadInput"
            accept=".csv"
            (change)="onFileUpload($event)" />
          <div class="drop-zone-content">
            <div class="title text-center">
              @if (fileName) {
                <div class="d-flex align-items-center mb-2">
                  <div class="me-3">{{ fileName }}</div>
                  <button class="btn-sm" (click)="deleteFile()" mat-icon-button>
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
                @if (uploadError) {
                  <div class="text-error">{{ uploadError }}</div>
                }
              } @else {
                <div>Drop file here to upload</div>
                <div class="mb-2">or</div>
                <div>
                  <label for="uploadInput" class="file-input-label">Browse for file</label>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</div>

<ng-template #uploadSummaryDialog>
  <app-modal-dialog id="uploadSummaryDialog">
    <div class="upload-summary-content">
      <div class="mb-3">Total Records: {{ uploadSummary.total }}</div>
      <div class="summary-block mb-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="block-title">Failed ({{ uploadSummary.failed.length }})</div>
          @if (uploadSummary.failed.length) {
            <a href="javascript:void(0)" class="app-link" (click)="onCopyListClick('failed')">Copy List</a>
          }
        </div>
        <div class="divider mb-2"></div>
        @if (uploadSummary.failed.length) {
          @for (item of uploadSummary.failed; track item) {
            <div [ngClass]="item.errors?.length ? '' : 'mb-2'">{{ item.name + ' - ' + item.npn + ':' }}</div>
            @if (item.errors?.length) {
              <div class="summary-item-errors mb-2">
                Errors in field:
                @for (error of item.errors; track error) {
                  <span>{{ error }}; </span>
                }
              </div>
            }
          }
        }
      </div>
      <div class="summary-block mb-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="block-title">Updated ({{ uploadSummary.updated.length }})</div>
          @if (uploadSummary.updated.length) {
            <a href="javascript:void(0)" class="app-link" (click)="onCopyListClick('updated')">Copy List</a>
          }
        </div>
        <div class="divider mb-2"></div>
        @if (uploadSummary.updated.length) {
          @for (item of uploadSummary.updated; track item) {
            <div class="mb-2">{{ item.name + ' (' + item.npn + ')' }}</div>
          }
        }
      </div>
      <div class="summary-block mb-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="block-title">Added ({{ uploadSummary.added.length }})</div>
          @if (uploadSummary.added.length) {
            <a href="javascript:void(0)" class="app-link" (click)="onCopyListClick('added')">Copy List</a>
          }
        </div>
        <div class="divider mb-2"></div>
        @if (uploadSummary.added.length) {
          @for (item of uploadSummary.added; track item) {
            <div class="mb-2">{{ item.name + ' (' + item.npn + ')' }}</div>
          }
        }
      </div>
      <div class="summary-block mb-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="block-title">Ignored ({{ uploadSummary.ignored.length }})</div>
          @if (uploadSummary.ignored.length) {
            <a href="javascript:void(0)" class="app-link" (click)="onCopyListClick('ignored')">Copy List</a>
          }
        </div>
        @if (uploadSummary.ignored.length) {
          <div class="divider mb-2"></div>
          @for (item of uploadSummary.ignored; track item) {
            <div class="mb-2">{{ item.name + ' (' + item.npn + ')' }}</div>
          }
        }
      </div>
    </div>
  </app-modal-dialog>
</ng-template>
