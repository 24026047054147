import { Injectable } from '@angular/core';
import { NavigationService } from './navigation.service';
import { jwtDecode } from 'jwt-decode';
import { routesPaths } from '../../app.routes';
import {MatDialogRef, MatDialogState} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authToken: string | null;
  private role: string | null;
  private dialogsArr: MatDialogRef<any>[] = [];

  constructor(private navService: NavigationService) {}

  logout() {
    this.checkOpenedDialogs();
    this.deleteToken();
    this.navService.navigate(routesPaths.login);
  }

  setToken(token: string) {
    this.authToken = token;
    sessionStorage.setItem('authToken', this.authToken);
    this.setRole(this.authToken);
  }

  setRole(token: string) {
    const decodedToken = jwtDecode(token);
    this.role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
  }

  getToken() {
    return this.authToken;
  }

  getTokenFromStorage() {
    this.authToken = sessionStorage.getItem('authToken');
    if (this.authToken) {
      this.setRole(this.authToken);
    }
  }

  getRole() {
    return this.role;
  }

  deleteToken() {
    this.authToken = null;
    this.role = null;
    sessionStorage.removeItem('authToken');
  }

  addOpenedDialog(dialog: MatDialogRef<any>) {
    this.dialogsArr.push(dialog);
  }

  private checkOpenedDialogs() {
    if (this.dialogsArr.length) {
      this.dialogsArr.forEach((dialog) => {
        if (dialog.getState() === MatDialogState.OPEN) {
          dialog.close();
        }
      })
      this.dialogsArr = [];
    }
  }
}
