import { StatusesEnum } from '../../shared/enums/statuses.enum';

export const AGENTS_TABLE_COLUMNS = [
  'name',
  'npn',
  'email',
  'phone',
  'distributionName',
  'licensedInStates',
  'isActive',
  'actions',
];

export const DEFAULT_PAGE_PARAMS = {
  PageIndex: 0,
  PageSize: 10,
  ByName: '',
  skipLoader: true,
};

export const FILTER_TABS_CONFIG = [
  {
    icon: 'account_circle',
    label: 'Agents',
    filterType: StatusesEnum.Quoting,
  },
];
