import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Subject, finalize, takeUntil } from 'rxjs';
import { QuotesRepository } from '../../repositories/quotes.repository';
import { regexPatterns } from '../../utils/regex';
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-share-quote-dialog',
  standalone: true,
  imports: [MatIcon, ReactiveFormsModule, MatInputModule, FormsModule, MatFormFieldModule, MatProgressSpinnerModule, MatButton],
  templateUrl: './share-quote-dialog.component.html',
  styleUrl: './share-quote-dialog.component.scss',
})
export class ShareQuoteDialogComponent implements AfterViewInit, OnDestroy {
  @Input() shareLinkValue: string;
  @Input() listBillIdValue: string;
  @Input() showCancelBtn: boolean;
  @Output() closeShareQuoteDialog: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('userEmail') userEmailElementRef: ElementRef;
  userEmailControl: FormControl<string | null> = new FormControl('');
  isLinkCopied: boolean = false;
  isEmailSubmitted: boolean = false;
  isInProgress: boolean = false;
  displayDuration: number = 3000;
  destroy$ = new Subject<void>();

  constructor(
    private quotesRepository: QuotesRepository,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.userEmailElementRef.nativeElement.focus();
    this.cdr.detectChanges();
  }

  copyLink(): void {
    navigator.clipboard.writeText(this.shareLinkValue).then(() => {
      this.isLinkCopied = true;
      setTimeout(() => {
        this.isLinkCopied = false;
      }, this.displayDuration);
    });
  }

  shareByEmailClick() {
    this.userEmailControl.setValidators(
      Validators.compose([Validators.required, Validators.pattern(regexPatterns.email)])
    );
    this.userEmailControl.updateValueAndValidity();

    if (this.userEmailControl.invalid) {
      this.userEmailControl.markAllAsTouched();
    } else {
      this.isInProgress = true;
      this.userEmailElementRef.nativeElement.blur();
      if (this.listBillIdValue == undefined) {
        this.listBillIdValue = '';
      }

      this.quotesRepository
        .shareLinkByEmail(this.userEmailControl.value, this.listBillIdValue)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => (this.isInProgress = false))
        )
        .subscribe(() => {
          this.isEmailSubmitted = true;

          setTimeout(() => {
            this.isEmailSubmitted = false;
            this.userEmailControl.reset();
          }, this.displayDuration);
        });
    }
  }

  closeShareLinkDialog(): void {
    this.closeShareQuoteDialog.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
