import { Component, OnInit } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AuthService } from '../../services/auth.service';
import { QuotesRepository } from '../../repositories/quotes.repository';
import {NavigationEnd, Router, RouterLink, RouterLinkActive} from '@angular/router';
import { take } from 'rxjs';
import { rolesEnum } from '../../enums/roles.enum';
import { unAuthorizedRoutes } from '../../../app.routes';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NgOptimizedImage, MatButton, MatIcon, MatIconButton, MatAnchor, RouterLinkActive, RouterLink],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  isAuthorised: boolean;
  linkToLoomis: string;
  isAgent: boolean;
  constructor(
    private quotesRepository: QuotesRepository,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const navigatedRoutePath = event.url.split('/')[1];
        if (!navigatedRoutePath || unAuthorizedRoutes.includes(navigatedRoutePath)) {
          this.isAuthorised = false;
        } else {
          this.isAuthorised = true;
          this.isAgent = this.authService.getRole() === rolesEnum.Agent;
          if (this.isAgent && !this.linkToLoomis) {
            this.setLinkToLoomis();
          }
        }
      }
    });
  }

  setLinkToLoomis(): void {
    this.quotesRepository
      .getLinkToLoomis()
      .pipe(take(1))
      .subscribe((res: string) => {
        this.linkToLoomis = res;
      });
  }

  onLogoutClick() {
    this.authService.logout();
  }

  onBrokerResourcesClick() {
    window.open('https://flexbenefits.co/brokers/broker-resource-center/', '_blank');
  }
}
