import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { routesPaths } from '../../app.routes';
import { LoginHelpInfoComponent } from '../../shared/components/login-help-info/login-help-info.component';
import { AuthRepository } from '../../shared/repositories/auth.repository';
import { NavigationService } from '../../shared/services/navigation.service';
import { regexPatterns } from '../../shared/utils/regex';

@Component({
  selector: 'app-new-password',
  standalone: true,
  imports: [
    LoginHelpInfoComponent,
    MatButton,
    MatError,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatSuffix,
    MatHint,
    ReactiveFormsModule,
    MatCheckbox,
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  token: string;
  isFormSubmitted: boolean;
  hidenew = true;
  hideconfirm = true;
  passwordMatchValidator: ValidatorFn;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authRepository: AuthRepository,
    private navService: NavigationService
  ) {}

  ngOnInit() {
    this.route.params.pipe(take(1)).subscribe(params => {
      if (params['token']) {
        this.token = location.pathname.split('/')[2];
      }
    });
    this.buildForm();
  }

  buildForm() {
    this.setPasswordMatchValidator();
    this.resetPasswordForm = this.fb.group({
      newPassword: [null, [Validators.required, Validators.minLength(8), Validators.pattern(regexPatterns.password)]],
      confirmPassword: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(regexPatterns.password),
          this.passwordMatchValidator,
        ],
      ],
    });
  }

  setPasswordMatchValidator() {
    this.passwordMatchValidator = (control: FormGroup): ValidationErrors | null => {
      const newPassword = this.resetPasswordForm?.get('newPassword')?.value;
      const confirmPassword = control?.value;
      return newPassword === confirmPassword ? null : { passwordMismatch: true };
    };
  }

  onSubmit() {
    if (this.resetPasswordForm.valid) {
      const requestParams = { token: this.token, newPassword: this.resetPasswordForm.value.newPassword };
      this.authRepository.resetPassword(requestParams).subscribe(() => {
        this.isFormSubmitted = true;
      });
    } else {
      this.resetPasswordForm.markAllAsTouched();
    }
  }

  onBackToLoginClick() {
    this.navService.navigate(routesPaths.login);
  }
}
