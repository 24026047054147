import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { fromEvent, merge, Subscription, timer } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root',
})
export class InactivityService implements OnDestroy {
  private inactivityTimeout = 2700 * 1000; // 45 minutes in milliseconds
  private userActivityEvents: Subscription | undefined;
  private inactivitySubscription: Subscription | undefined;

  constructor(private ngZone: NgZone, private authService: AuthService) {}

  startInactivityTimer() {
    // Run outside zone to prevent unnecessary change detection
    this.ngZone.runOutsideAngular(() => {
      const activityEvents = [
        fromEvent(document, 'mousemove'),
        fromEvent(document, 'mousedown'),
        fromEvent(document, 'keypress'),
        fromEvent(document, 'touchstart'),
        fromEvent(document.getElementsByClassName("page-content"), 'scroll'),
      ];

      // Merge all activity events into a single stream
      this.userActivityEvents = merge(...activityEvents)
        .pipe(
          tap(() => this.resetInactivityTimer()),  // Reset timer on each event
          switchMap(() => timer(this.inactivityTimeout)), // Start new inactivity timer
          tap(() => this.handleLogout())  // Trigger logout when timer completes
        )
        .subscribe();
    });
  }

  resetInactivityTimer() {
    if (this.inactivitySubscription) {
      this.inactivitySubscription.unsubscribe();
    }

    this.inactivitySubscription = timer(this.inactivityTimeout)
      .subscribe(() => this.handleLogout());
  }

  handleLogout() {
    this.ngZone.run(() => {
      this.authService.logout();
    });
  }

  ngOnDestroy() {
    if (this.userActivityEvents) {
      this.userActivityEvents.unsubscribe();
    }
    if (this.inactivitySubscription) {
      this.inactivitySubscription.unsubscribe();
    }
  }
}
