import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {SearchComponent} from "../../shared/components/search/search.component";
import {FiltersTab} from "../../shared/models/common.interface";
import {PageParams} from "../../shared/models/quotes.interface";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef, MatNoDataRow, MatRow, MatRowDef, MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {debounceTime, distinctUntilChanged, finalize, Subject, take, takeUntil} from "rxjs";
import {getHttpParams} from "../../shared/utils/create-http-params";
import {BILLS_TABLE_COLUMNS, DEFAULT_PAGE_PARAMS, FILTER_TABS_CONFIG} from "./bills-dashboard.config";
import {BillsRepository} from "../../shared/repositories/bills.repository";
import {BillsGridResponse, IBill, IPackage} from "../../shared/models/bills.interface";
import {FullWidthScrollDirective} from "../../shared/directives/full-width-scroll.directive";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatTabLink, MatTabNav, MatTabNavPanel} from "@angular/material/tabs";
import {ModalDialogComponent} from "../../shared/components/modal-dialog/modal-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {GlobalMessagesService} from "../../shared/services/global-messages.service";
import {BillDialogComponent} from "./components/bill-dialog/bill-dialog.component";
import {QuotesRepository} from "../../shared/repositories/quotes.repository";
import {ShareQuoteDialogComponent} from "../../shared/components/share-quote-dialog/share-quote-dialog.component";

@Component({
  selector: 'app-bills-dashboard',
  standalone: true,
  imports: [
    FullWidthScrollDirective,
    MatButton,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MatPaginator,
    MatRow,
    MatRowDef,
    MatTabLink,
    MatTabNav,
    MatTabNavPanel,
    MatTable,
    ModalDialogComponent,
    SearchComponent,
    MatMenuTrigger,
    MatHeaderCellDef,
    MatNoDataRow,
    ShareQuoteDialogComponent
  ],
  templateUrl: './bills-dashboard.component.html',
  styleUrl: './bills-dashboard.component.scss'
})
export class BillsDashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('search') searchComponent: SearchComponent;

  filterTabs = FILTER_TABS_CONFIG;
  activeTab: FiltersTab = this.filterTabs[0];
  pageParams: PageParams = DEFAULT_PAGE_PARAMS;
  displayedColumns = BILLS_TABLE_COLUMNS;
  pageSizeOptions = [10, 25, 50];
  billsTotalCount: number;
  listBillPdfTemplateLink: string =
    'https://flexbenefitstorage.blob.core.windows.net/prod/packageBrochures/ListBillAgreement-FlexBenefits.pdf';
  data: IBill[] = [];
  dataSource = new MatTableDataSource(this.data);
  isInProgress: boolean = false;
  searchValue: string = '';
  isShowShareLinkDialog: boolean = false;
  shareLink: string;
  shareLinkCurrentValue: string;
  listBillIdCurrentValue: string;
  packagesList: IPackage[] = [];
  destroy$ = new Subject<void>();

  constructor(
    private billsRepository: BillsRepository,
    private dialog: MatDialog,
    private messageService: GlobalMessagesService,
    private quotesRepository: QuotesRepository
  ) {}

  ngOnInit() {
    this.getPackagesList();
    this.setFilter(this.activeTab);
    this.setData(this.pageParams);
    this.setSharedLink();
  }

  getPackagesList() {
    this.billsRepository.getPackagesList().pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res.length) {
        this.packagesList = res;
      }
    });
  }

  ngAfterViewInit() {
    this.paginator.selectConfig.panelClass = 'select-dropdown-paginator';
    this.onSearchQueryChange();
  }

  setData(pageParams: PageParams): void {
    const params = getHttpParams(pageParams);
    this.isInProgress = true;
    this.billsRepository
      .getBillsData(params)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isInProgress = false))
      )
      .subscribe((res: BillsGridResponse) => {
        this.billsTotalCount = res.total;
        this.dataSource = new MatTableDataSource(res.data);
      });
  }

  onSearchQueryChange(): void {
    this.searchComponent.searchControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((searchValue: string) => {
        this.searchValue = searchValue;
        this.pageParams = {
          ...this.pageParams,
          PageIndex: 0,
          ByName: searchValue,
        };

        this.setData(this.pageParams);
      });
  }

  applyFilter(currentTab: FiltersTab): void {
    this.activeTab = currentTab;
    this.setFilter(currentTab);
    this.setData(this.pageParams);
  }

  setFilter(tab: FiltersTab): void {
    if (tab.filterType) {
      this.pageParams = {
        ...DEFAULT_PAGE_PARAMS,
        PageSize: this.pageParams.PageSize,
        ByName: this.searchValue,
      };
    } else {
      this.pageParams = {
        ...DEFAULT_PAGE_PARAMS,
        PageSize: this.pageParams.PageSize,
        ByName: this.searchValue,
      };
    }
  }

  createBill() {
    this.openBillDialog('create');
  }

  editBill(bill: IBill) {
    this.billsRepository.getBill(bill.id).pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.openBillDialog('edit', res);
    });
  }

  createQuote(bill: IBill) {
    const url = `${this.shareLink}&ListBill=${bill.id}&isAgentMode=true`;
    window.open(url, '_blank');
  }

  shareLinkClick(bill: IBill) {
    this.shareLinkCurrentValue = `${this.shareLink}&ListBill=${bill.id}`;
    this.listBillIdCurrentValue = `${bill.id}`;
    this.isShowShareLinkDialog = !this.isShowShareLinkDialog;
  }

  openBillDialog(type: 'create' | 'edit', bill?: IBill) {
    const dialogRef = this.dialog.open(BillDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '600px',
      data: {
        title: `${type} Group List Bill`,
        type: type,
        bill: bill,
        packagesList: this.packagesList,
      }
    });

    dialogRef.afterClosed().subscribe((success: boolean) => {
      if (success) {
        this.setData(this.pageParams);
        this.messageService.show('success', `Group was ${type === 'create' ? 'created' : 'changed'}.`);
      }
    });
  }

  onPaginatorChange($event: PageEvent): void {
    this.pageParams.PageIndex = $event.pageIndex;
    this.pageParams.PageSize = $event.pageSize;
    this.setData(this.pageParams);
  }

  setSharedLink(): void {
    this.quotesRepository
      .getSharedLink()
      .pipe(take(1))
      .subscribe(link => {
        this.shareLink = link;
      });
  }

  closeShareQuoteDialog(): void {
    this.isShowShareLinkDialog = false;
  }

  shareLinkOverlayClick(event) {
    event.stopPropagation();
    if (event.target.classList.contains('share-link-dialog-container')) {
      this.closeShareQuoteDialog();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
