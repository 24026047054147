<div class="reset-password-content h-100 w-100">
  <h1 class="page-title text-center mb-0">Welcome to Flex Benefits!</h1>
  <div class="d-flex justify-content-center">
    <div class="reset-password-form-container form-container">
      <div class="d-flex justify-content-center">
        <form class="reset-password-form form" [formGroup]="forgotPasswordForm">
          <a
            href="javascript:void(0)"
            class="back-to-login-link text-primary-blue text-decoration-none d-flex align-items-center mb-4"
            (click)="onBackToLoginClick()">
            <mat-icon>chevron_left</mat-icon>
            <span>Back to Login</span>
          </a>
          <div class="form-title mb-5">Reset password</div>
          @if (!resetLinkSubmitted) {
            <mat-form-field class="w-100 mb-4" appearance="outline">
              <mat-label>Enter your account email</mat-label>
              <input matInput formControlName="email" />
              <mat-icon matSuffix>email</mat-icon>
              @if (forgotPasswordForm.controls.email.errors?.required) {
                <mat-error>Email is required.</mat-error>
              }
              @if (forgotPasswordForm.controls.email.errors?.pattern) {
                <mat-error>Email format does not match.</mat-error>
              }
            </mat-form-field>
            <div class="mb-4">
              <button mat-raised-button color="primary" class="w-100" type="button" (click)="onSubmitClick()">
                Send Reset Password Link
              </button>
            </div>
          }
          @if (resetLinkSubmitted) {
            <div class="mb-4">
              Reset password link was sent to your Email. Follow the instruction to complete this process.
            </div>
            <div class="mb-4">
              <button mat-raised-button color="primary" class="w-100" type="button" (click)="onBackToLoginClick()">
                <mat-icon>check</mat-icon>
              </button>
            </div>
          }
          <app-login-help-info></app-login-help-info>
        </form>
      </div>
    </div>
  </div>
</div>
