import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BillsGridResponse } from "../models/bills.interface";

@Injectable({
  providedIn: 'root',
})
export class BillsRepository {

  constructor(private http: HttpClient) {}

  getBillsData(pageParams: HttpParams): Observable<BillsGridResponse> {
    const url = `${environment.apiUrl}/api/v1/ListBill/GetListBillPage`;
    return this.http.get<BillsGridResponse>(url, { params: pageParams });
  }

  createBill(data) {
    const url = `${environment.apiUrl}/api/v1/ListBill`;
    return this.http.post(url, data);
  }
}
