import { Component } from '@angular/core';

@Component({
  selector: 'app-login-help-info',
  standalone: true,
  imports: [],
  templateUrl: './login-help-info.component.html',
  styleUrl: './login-help-info.component.scss',
})
export class LoginHelpInfoComponent {
  info = { title: 'Connect with FLEX BENEFITS help desk', tel: '866-390-FLEX (3539)', email: 'contracting@flexbenefits.co' };
}
