import { AfterViewChecked, AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appFullWidthScroll]',
  standalone: true,
})
export class FullWidthScrollDirective implements AfterViewInit, AfterViewChecked {
  @Input() scrollableElement: HTMLElement;
  target: HTMLElement;

  constructor(private elementRef: ElementRef) {
    this.target = this.elementRef.nativeElement;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setTargetWidth();
  }

  ngAfterViewInit(): void {
    this.setTargetWidth();
  }

  ngAfterViewChecked(): void {
    this.setTargetWidth();
  }

  setTargetWidth(): void {
    const actualWidth = this.scrollableElement?.scrollWidth;
    this.target.style.width = `${actualWidth}px`;
  }
}
