import { StatusesEnum } from '../../shared/enums/statuses.enum';

export const BILLS_TABLE_COLUMNS = [
  'name',
  'nPayPeriod',
  'startDate',
  'endDate',
  'groupSize',
  'loomisNum',
  'actions',
];

export const DEFAULT_PAGE_PARAMS = {
  PageIndex: 0,
  PageSize: 10,
  ByName: '',
  skipLoader: true,
};

export const FILTER_TABS_CONFIG = [
  {
    icon: 'attach_money',
    label: 'Groups',
    filterType: StatusesEnum.Quoting,
  },
];
