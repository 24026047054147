<div class="wrap-dialog-content">
  <div class="d-flex align-items-center justify-content-between pb-3">
    <span class="dialog-title">{{data.title}}</span>
    <button type="button" mat-icon-button class="close-btn" (click)="onCanceled()">
      <mat-icon class="action-menu-icon">close</mat-icon>
    </button>
  </div>
  <div>
    <form [formGroup]="form">
      <div class="mb-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Name</mat-label>
          <input
            matInput
            formControlName="name"/>
          @if (form.controls.name.errors?.required) {
            <mat-error>Name is required</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="mb-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Email</mat-label>
          <input
            matInput
            formControlName="email"/>
          @if (form.controls.email.errors?.required) {
            <mat-error>Email is required</mat-error>
          }
          @if (form.controls.email.errors?.pattern) {
            <mat-error>Email format does not match.</mat-error>
          }
          @if (form.controls.email.errors?.notUnique) {
            <mat-error>This email is already being used.</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="mb-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>NPN</mat-label>
          <input
            matInput
            formControlName="npn"/>
          @if (form.controls.npn.errors?.required) {
            <mat-error>NPN is required</mat-error>
          }
          @if (form.controls.npn.errors?.pattern) {
            <mat-error>Only numbers are allowed.</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="mb-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Phone</mat-label>
          <input
            matInput
            formControlName="phone"/>
          @if (form.controls.phone.errors?.required) {
            <mat-error>Phone is required</mat-error>
          }
          @if (form.controls.phone.errors?.pattern) {
            <mat-error>Invalid Phone Number format.</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="mb-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>State Licenses</mat-label>
          <mat-select formControlName="stateLicenses" multiple>
            <mat-select-trigger>
              @if (form.controls.stateLicenses.value.length) {
                {{form.controls.stateLicenses.value.length === 1 ? '1 state selected' : form.controls.stateLicenses.value.length + ' states selected'}}
              }
            </mat-select-trigger>
            @for (state of statesList; track state) {
              <mat-option [value]="state">{{state}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="mb-2">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Distribution</mat-label>
          <mat-select formControlName="distributionId">
            @for (distribution of data.distributions; track distribution) {
              <mat-option [value]="distribution.key">{{ distribution.value }}</mat-option>
            }
          </mat-select>
          @if (form.controls.distributionId.errors?.required) {
            <mat-error>Distribution is required</mat-error>
          }
        </mat-form-field>
      </div>
      <div>
        <mat-slide-toggle labelPosition="before" formControlName="isActive">Is Active</mat-slide-toggle>
      </div>
    </form>
  </div>
  <div class="dialog-footer">
    <div mat-dialog-actions class="dialog-actions d-flex align-items-center justify-content-end p-0">
      <button mat-button color="primary" class="btn-sm" (click)="onCanceled()">Cancel</button>
      <button mat-raised-button class="btn-sm" color="primary" (click)="submit()">{{data.type === 'create' ? 'Create' : 'Edit'}}</button>
    </div>
  </div>
</div>
