<div class="page-content-container h-100 mt-4">
  <div class="top-content">
    <div class="w-100">
      <div class="d-flex flex-column flex-lg-row justify-content-between align-items-md-center pb-3">
        <div class="sub-title ps-0 ps-lg-2 mb-4 mb-lg-0">List Bill Manager</div>
        <div class="d-flex section-actions">
          <a mat-stroked-button class="btn-lg create-btn" href="{{ listBillPdfTemplateLink }}" target="_blank">
            <span class="external-link d-flex align-items-center">
              <span>Employer List Bill Application PDF</span>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="main-content">
    <div class="scrollable-area">
      <div class="w-100">
        <div class="section-filters">
          <nav mat-tab-nav-bar [tabPanel]="tabPanel">
            @for (tab of filterTabs; track tab.label) {
              <a mat-tab-link (click)="applyFilter(tab)" [active]="activeTab === tab">
                <mat-icon>{{ tab.icon }}</mat-icon>
                <span class="tab-label">{{ tab.label }}</span>
              </a>
            }
          </nav>
        </div>
        <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
      </div>

      <div class="w-100">
        <div class="separating-line" appFullWidthScroll [scrollableElement]="scrollableElement"></div>
        <div class="search-wrapper d-flex justify-content-between">
          <app-search #search></app-search>
          <button
            mat-raised-button
            color="primary"
            class="create-btn btn-lg"
            type="button"
            (click)="createBill()">
            <span>Create Group</span>
          </button>
        </div>

        <div #scrollableElement>
          <table mat-table [dataSource]="dataSource" class="app-table">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Group Name</th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <ng-container matColumnDef="nPayPeriod">
              <th mat-header-cell *matHeaderCellDef>Number of pay periods per year</th>
              <td mat-cell *matCellDef="let element">{{ element.nPayPeriod }}</td>
            </ng-container>

            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef>Start date</th>
              <td mat-cell *matCellDef="let element">{{ element?.startDate }}</td>
            </ng-container>

            <ng-container matColumnDef="endDate">
              <th mat-header-cell *matHeaderCellDef>End date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.endDate }}
              </td>
            </ng-container>

            <ng-container matColumnDef="groupSize">
              <th mat-header-cell *matHeaderCellDef>Group size</th>
              <td mat-cell *matCellDef="let element">
                {{ element.groupSize }}
              </td>
            </ng-container>

            <ng-container matColumnDef="loomisNum">
              <th mat-header-cell *matHeaderCellDef>Account number</th>
              <td mat-cell *matCellDef="let element">
                {{ element.loomisNum }}
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let element">
                <button type="button" class="action-menu-btn" mat-icon-button [matMenuTriggerFor]="action_menu">
                  <mat-icon class="action-menu-icon">more_horizontal</mat-icon>
                </button>
                <mat-menu #action_menu="matMenu" xPosition="before">
                  <button mat-menu-item (click)="editBill(element)">
                    <span>Edit</span>
                  </button>
                  <button mat-menu-item (click)="createQuote(element)">
                    <span>Create quote</span>
                  </button>
                  <button mat-menu-item (click)="shareLinkClick(element)">
                    <span>Share link</span>
                  </button>
                </mat-menu>
              </td>
            </ng-container>

            <tr class="mat-row row-no-data" *matNoDataRow>
              <td class="mat-cell cell-no-data" [attr.colspan]="displayedColumns.length">
                <div class="d-flex flex-column wrap-cell-no-data">
                  @if (!isInProgress) {
                    <img class="no-data-img" src="assets/img/no_data.png" alt="no-data" />
                    @if (searchComponent?.searchControl?.value) {
                      <span class="no-results-title">No results found</span>
                      <span class="no-results-subtitle">
                        Try to adjust your search or filter options to find what you are looking for.
                      </span>
                    } @else {
                      <span class="no-results-title">There are no records yet</span>
                    }
                  }
                </div>
              </td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-row"></tr>
          </table>
        </div>
      </div>
    </div>

    <div class="w-100">
      <div class="pagination-container d-flex align-items-center justify-content-end">
        <mat-paginator
          #paginator
          class="app-paginator"
          [pageSizeOptions]="pageSizeOptions"
          [length]="billsTotalCount"
          [pageIndex]="pageParams.PageIndex"
          [showFirstLastButtons]="true"
          (page)="onPaginatorChange($event)">
        </mat-paginator>
      </div>
    </div>
  </div>
  @if (isShowShareLinkDialog && shareLink) {
    <div class="share-link-dialog-container" (click)="shareLinkOverlayClick($event)">
      <app-share-quote-dialog
        [shareLinkValue]="shareLinkCurrentValue"
        [listBillIdValue]="listBillIdCurrentValue"
        [showCancelBtn]="true"
        (closeShareQuoteDialog)="closeShareQuoteDialog()">
      </app-share-quote-dialog>
    </div>
  }
</div>
