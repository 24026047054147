import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { routesPaths, unAuthorizedRoutes } from './app.routes';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { AuthService } from './shared/services/auth.service';
import { NavigationService } from './shared/services/navigation.service';
import { environment } from '../environments/environment';
import { AppRepository } from './shared/repositories/app.repository';
import {InactivityService} from "./shared/services/inactivity.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, MatButtonModule, FooterComponent, HeaderComponent, LoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  isEnvSettingsReady: boolean;
  isProd: boolean;
  constructor(
    private router: Router,
    private authService: AuthService,
    private appRepository: AppRepository,
    private navService: NavigationService,
    private inactivityService: InactivityService,
  ) {}

  ngOnInit() {
    this.setEnvSettings();
    this.handleAuth();
  }

  handleAuth() {
    this.authService.getTokenFromStorage();
    this.initRouterListener();
    this.inactivityService.startInactivityTimer();
  }

  initRouterListener() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const navigatedRoutePath = event.url.split('/')[1];
        if (!unAuthorizedRoutes.includes(navigatedRoutePath) && !this.authService.getToken()) {
          this.navService.navigate(routesPaths.login);
        }
      }
    });
  }

  setEnvSettings() {
    const isLocalhost = location.hostname === 'localhost';
    if (!isLocalhost) {
      this.appRepository.getEnvSettings().subscribe(res => {
        this.isProd = res.production;
        environment.production = res.production;
        environment.name = res.name;
        environment.apiUrl = res.apiUrl;
        this.isEnvSettingsReady = true;
      });
    } else {
      this.isEnvSettingsReady = true;
    }
  }
}
