import { Component, Input } from '@angular/core';
import { STATUSES_LABEL, StatusesEnum } from '../../enums/statuses.enum';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-status',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './status.component.html',
  styleUrl: './status.component.scss',
})
export class StatusComponent {
  @Input() set status(value: number) {
    this.statusCode = value;
    this.statusLabel = STATUSES_LABEL[value];
  }
  statuses = StatusesEnum;
  statusLabel: string;
  statusCode: number;
}
