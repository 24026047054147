import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { catchError, finalize, throwError } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { GlobalMessagesService } from '../services/global-messages.service';

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const authService = inject(AuthService);
  const loaderService = inject(LoaderService);
  const messagesService = inject(GlobalMessagesService);
  const userToken = authService.getToken();

  if (!req.params.get('skipLoader') || req.params.get('skipLoader') === 'false') {
    loaderService.show();
  }

  const modifiedReq = req.clone({
    headers: req.headers.set('Authorization', `Bearer ${userToken}`),
    params: req.params.delete('skipLoader'),
  });
  const isAuthRequest = req.url.includes('Auth') && !req.url.includes('logout');

  return next(isAuthRequest ? req : modifiedReq).pipe(
    catchError(err => {
      if (err.status === 401) {
        authService.logout();
      } else {
        messagesService.show('error', 'Server error has occurred.');
      }
      return throwError(() => err);
    }),
    finalize(() => {
      loaderService.hide();
    })
  );
};
