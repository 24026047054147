import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { QuotesDashboardComponent } from './pages/quotes-dashboard/quotes-dashboard.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { AgentsDashboardComponent } from './pages/agents-dashboard/agents-dashboard.component';
import {BillsDashboardComponent} from "./pages/bills-dashboard/bills-dashboard.component";

export const routesPaths = {
  login: 'login',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  quotesDashboard: 'quotes-dashboard',
  agentsDashboard: 'agents-dashboard',
  billsDashboard: 'bills-dashboard',
};

export const unAuthorizedRoutes: string[] = [routesPaths.login, routesPaths.forgotPassword, routesPaths.resetPassword];

export const routes: Routes = [
  {
    path: routesPaths.login,
    title: 'Flexbenefits - Login',
    component: LoginComponent,
  },
  {
    path: routesPaths.forgotPassword,
    title: 'Flexbenefits - Forgot Password',
    component: ForgotPasswordComponent,
  },
  {
    path: `${routesPaths.resetPassword}/:token`,
    title: 'Flexbenefits - Reset Password',
    component: ResetPasswordComponent,
  },
  {
    path: routesPaths.quotesDashboard,
    title: 'Flexbenefits - Quotes Dashboard',
    component: QuotesDashboardComponent,
  },
  {
    path: routesPaths.billsDashboard,
    title: 'Flexbenefits - Bills Dashboard',
    component: BillsDashboardComponent,
  },
  {
    path: routesPaths.agentsDashboard,
    title: 'Flexbenefits - Agents Dashboard',
    component: AgentsDashboardComponent,
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];
