import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {BillsGridResponse, IBill, IPackage} from "../models/bills.interface";

@Injectable({
  providedIn: 'root',
})
export class BillsRepository {

  constructor(private http: HttpClient) {}

  getBillsData(pageParams: HttpParams): Observable<BillsGridResponse> {
    const url = `${environment.apiUrl}/api/v1/ListBill/GetListBillPage`;
    return this.http.get<BillsGridResponse>(url, { params: pageParams });
  }

  getBill(id: string): Observable<IBill> {
    const url = `${environment.apiUrl}/api/v1/ListBill/${id}`;
    return this.http.get<IBill>(url);
  }

  createBill(data) {
    const url = `${environment.apiUrl}/api/v1/ListBill`;
    return this.http.post(url, data);
  }

  getPackagesList(): Observable<IPackage[]> {
    const url = `${environment.apiUrl}/api/v1/Package`;
    return this.http.get<IPackage[]>(url);
  }
}
