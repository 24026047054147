import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentSettings } from '../models/common.interface';

@Injectable({
  providedIn: 'root',
})
export class AppRepository {
  constructor(private http: HttpClient) {}

  getEnvSettings(): Observable<EnvironmentSettings> {
    const url = `/assets/appSettings.json`;
    return this.http.get<EnvironmentSettings>(url);
  }
}
