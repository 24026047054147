import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { rolesEnum } from '../enums/roles.enum';
import { routesPaths } from '../../app.routes';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private router: Router) {}

  navigate(route: string, params: any[] = []) {
    this.router.navigate([route, ...params]);
  }

  defaultNavigateByRole(role: string | null) {
    if (role) {
      if (role === rolesEnum.Admin) {
        this.navigate(routesPaths.agentsDashboard);
      } else {
        this.navigate(routesPaths.quotesDashboard);
      }
    } else {
      this.navigate(routesPaths.login);
    }
  }
}
