<div class="new-password-content h-100 w-100">
  <h1 class="page-title text-center mb-0">Welcome to Flex Benefits!</h1>
  <div class="d-flex justify-content-center">
    <div class="login-form-container form-container">
      <div class="d-flex justify-content-center">
        <form class="login-form form" [formGroup]="resetPasswordForm">
          <div class="form-title mb-5">New Password</div>
          @if (!isFormSubmitted) {
            <mat-form-field class="w-100 mb-5" appearance="outline">
              <mat-label>Enter your new password</mat-label>
              <input matInput [type]="hidenew ? 'password' : 'text'" formControlName="newPassword" />
              <mat-icon matSuffix (click)="hidenew = !hidenew">{{
                hidenew ? 'visibility_off' : 'visibility'
              }}</mat-icon>
              <mat-hint
                >Password must be at least 8 characters long and contain one uppercase letter, one lowercase letter, one
                number, and one special character.
              </mat-hint>
              @if (resetPasswordForm.controls.newPassword.errors?.required) {
                <mat-error>Password is required.</mat-error>
              } @else if (resetPasswordForm.controls.newPassword.errors?.minlength) {
                <mat-error>Password must be at least 8 characters long.</mat-error>
              } @else if (resetPasswordForm.controls.newPassword.errors?.pattern) {
                <mat-error
                  >Password must contain at least one uppercase letter, one lowercase letter, one number, and one
                  special character.</mat-error
                >
              }
            </mat-form-field>
            <mat-form-field class="w-100 mb-4" appearance="outline">
              <mat-label>Confirm your new password</mat-label>
              <input matInput [type]="hideconfirm ? 'password' : 'text'" formControlName="confirmPassword" />
              <mat-icon matSuffix (click)="hideconfirm = !hideconfirm">{{
                hideconfirm ? 'visibility_off' : 'visibility'
              }}</mat-icon>
              @if (resetPasswordForm.controls.confirmPassword.errors?.required) {
                <mat-error>Password is required.</mat-error>
              } @else if (resetPasswordForm.controls.confirmPassword.errors?.minlength) {
                <mat-error>Password must be at least 8 characters long.</mat-error>
              } @else if (resetPasswordForm.controls.confirmPassword.errors?.pattern) {
                <mat-error
                  >Password must contain at least one uppercase letter, one lowercase letter, one number, and one
                  special character.</mat-error
                >
              } @else if (resetPasswordForm.controls.confirmPassword.errors?.passwordMismatch) {
                <mat-error>Passwords do not match.</mat-error>
              }
            </mat-form-field>
            <div class="mb-4">
              <button mat-raised-button color="primary" class="w-100" type="button" (click)="onSubmit()">
                Save New Password
              </button>
            </div>
          } @else {
            <div class="mb-4">
              <span>Your Password was updated!</span>
              <br />
              <span>Try to Login with new Password.</span>
            </div>
            <div class="mb-4">
              <button mat-raised-button color="primary" class="w-100" type="button" (click)="onBackToLoginClick()">
                Login
              </button>
            </div>
          }
          <app-login-help-info></app-login-help-info>
        </form>
      </div>
    </div>
  </div>
</div>
