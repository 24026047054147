import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatTabLink, MatTabNav, MatTabNavPanel } from '@angular/material/tabs';
import { ShareQuoteDialogComponent } from '../../shared/components/share-quote-dialog/share-quote-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SearchComponent } from '../../shared/components/search/search.component';
import { StatusComponent } from '../../shared/components/status/status.component';
import { ModalDialogComponent } from '../../shared/components/modal-dialog/modal-dialog.component';
import { AgentsUploadSummary, AgentsUploadSummaryItem } from '../../shared/models/admin.interface';
import { NavigationService } from '../../shared/services/navigation.service';
import { AuthService } from '../../shared/services/auth.service';
import { AgentsRepository } from '../../shared/repositories/agents.repository';
import { GlobalMessagesService } from '../../shared/services/global-messages.service';
import { ModalDialogData } from '../../shared/models/modal-dialog.interface';
import { DatePipe, NgClass } from '@angular/common';
import { PageParams } from '../../shared/models/quotes.interface';
import { AGENTS_TABLE_COLUMNS, DEFAULT_PAGE_PARAMS, FILTER_TABS_CONFIG } from './agents-dashboard.config';
import { debounceTime, distinctUntilChanged, finalize, Subject, takeUntil } from 'rxjs';
import { getHttpParams } from '../../shared/utils/create-http-params';
import { AgentsGridResponse, IAgent } from '../../shared/models/agents.interface';
import { FullWidthScrollDirective } from '../../shared/directives/full-width-scroll.directive';
import { FiltersTab } from '../../shared/models/common.interface';
import {AgentDialogComponent} from "./components/agent-dialog/agent-dialog.component";

@Component({
  selector: 'app-agents-dashboard',
  standalone: true,
  imports: [
    MatButton,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatMenu,
    MatPaginator,
    MatProgressBar,
    MatRow,
    MatRowDef,
    MatTabLink,
    MatTabNav,
    MatTabNavPanel,
    MatTable,
    SearchComponent,
    ShareQuoteDialogComponent,
    StatusComponent,
    ModalDialogComponent,
    MatIconButton,
    NgClass,
    DatePipe,
    FullWidthScrollDirective,
    MatMenuItem,
    MatHeaderCellDef,
    MatMenuTrigger,
    MatNoDataRow,
  ],
  templateUrl: './agents-dashboard.component.html',
  styleUrl: './agents-dashboard.component.scss',
})
export class AgentsDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('uploadSummaryDialog') uploadSummaryDialog: TemplateRef<any>;
  @ViewChild('uploadInput') uploadInput: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('search') searchComponent: SearchComponent;
  filterTabs = FILTER_TABS_CONFIG;
  uploadSummaryDialogRef: MatDialogRef<ModalDialogComponent>;
  fileName: string;
  showUploadModal: boolean;
  uploadSummary: AgentsUploadSummary;
  uploadError: string;
  pageParams: PageParams = DEFAULT_PAGE_PARAMS;
  displayedColumns = AGENTS_TABLE_COLUMNS;
  pageSizeOptions = [10, 25, 50];
  isInProgress: boolean = false;
  agentsTotalCount: number;
  data: IAgent[] = [];
  activeTab: FiltersTab = this.filterTabs[0];
  searchValue: string = '';
  distributions: {key: string, value: string}[];
  dataSource = new MatTableDataSource(this.data);
  destroy$ = new Subject<void>();
  constructor(
    private navService: NavigationService,
    private authService: AuthService,
    private dialog: MatDialog,
    private agentsRepository: AgentsRepository,
    private messageService: GlobalMessagesService
  ) {}

  ngOnInit() {
    this.navService.defaultNavigateByRole(this.authService.getRole());
    this.setFilter(this.activeTab);
    this.setData(this.pageParams);
    this.getDistributions();
  }

  ngAfterViewInit(): void {
    this.paginator.selectConfig.panelClass = 'select-dropdown-paginator';
    this.onSearchQueryChange();
  }

  getDistributions() {
    this.agentsRepository
      .getDistributions()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: {key: string, value: string}[]) => {
        this.distributions = res;
      });
  }

  onSearchQueryChange(): void {
    this.searchComponent.searchControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((searchValue: string) => {
        this.searchValue = searchValue;
        this.pageParams = {
          ...this.pageParams,
          PageIndex: 0,
          ByName: searchValue,
        };

        this.setData(this.pageParams);
      });
  }

  setData(pageParams: PageParams): void {
    const params = getHttpParams(pageParams);
    this.isInProgress = true;
    this.agentsRepository
      .getAgentsData(params)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isInProgress = false))
      )
      .subscribe((res: AgentsGridResponse) => {
        this.agentsTotalCount = res.total;
        this.dataSource = new MatTableDataSource(res.data);
      });
  }

  onDownloadTemplateClick() {
    this.agentsRepository.getTemplateDownloadLink().subscribe(res => {
      const link = document.createElement('a');
      link.href = res;
      link.target = '_blank';
      setTimeout(() => {
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, 100);
    });
  }

  onUploadClick() {
    this.showUploadModal = true;
  }

  hideUploadModal() {
    this.showUploadModal = false;
    this.deleteFile();
  }

  onUploadOverlayClick(e) {
    if (e.target.id === 'dropZoneOverlay') {
      this.hideUploadModal();
    }
  }

  showUploadSummaryDialog() {
    const confirmationModalData: ModalDialogData = {
      id: 'confirmationModalData',
      title: 'Uploading Summary',
      cancelActionText: 'Close',
      showCancelBtn: true,
      hasContent: true,
    };
    this.uploadSummaryDialogRef = this.dialog.open(this.uploadSummaryDialog, {
      data: confirmationModalData,
      width: '550px',
      autoFocus: false,
      disableClose: true,
    });

    this.uploadSummaryDialogRef.afterClosed().subscribe(() => {});
  }

  onFileUpload(event) {
    const file = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      if (file.type === 'text/csv') {
        this.agentsRepository.uploadAgentsList(file).subscribe(res => {
          this.hideUploadModal();
          this.uploadSummary = res;
          this.showUploadSummaryDialog();
          this.setData(this.pageParams);
        });
      } else {
        this.uploadError = 'File format must be CSV.';
        return;
      }
    }
  }

  deleteFile() {
    this.uploadInput.nativeElement.value = '';
    this.fileName = null;
    this.uploadError = null;
  }

  onCopyListClick(groupName: string) {
    const textValue = this.uploadSummary[groupName]
      .map((item: AgentsUploadSummaryItem) => `${item.name} (${item.npn})`)
      .join('\n');
    navigator.clipboard.writeText(textValue).then(() => {
      this.messageService.show('success', 'List copied.');
    });
  }

  onPaginatorChange($event: PageEvent): void {
    this.pageParams.PageIndex = $event.pageIndex;
    this.pageParams.PageSize = $event.pageSize;
    this.setData(this.pageParams);
  }

  applyFilter(currentTab: FiltersTab): void {
    this.activeTab = currentTab;
    this.setFilter(currentTab);
    this.setData(this.pageParams);
  }

  setFilter(tab: FiltersTab): void {
    if (tab.filterType) {
      this.pageParams = {
        ...DEFAULT_PAGE_PARAMS,
        PageSize: this.pageParams.PageSize,
        applicationStatusType: tab.filterType,
        ByName: this.searchValue,
      };
    } else {
      this.pageParams = {
        ...DEFAULT_PAGE_PARAMS,
        PageSize: this.pageParams.PageSize,
        ByName: this.searchValue,
      };
    }
  }

  editAgent(agent: IAgent) {
    this.openAgentDialog('edit', agent);
  }

  createAgent() {
    this.openAgentDialog('create');
  }

  openAgentDialog(type: 'create' | 'edit', agent?: IAgent) {
    const dialogRef = this.dialog.open(AgentDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '600px',
      data: {
        title: `${type} agent`,
        type: type,
        agent: agent,
        distributions: this.distributions
      }
    });

    dialogRef.afterClosed().subscribe((success: boolean) => {
      if (success) {
        this.setData(this.pageParams);
        this.messageService.show('success', 'Agent was created.');
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
