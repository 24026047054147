import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { GlobalMessageType } from '../../services/global-messages.service';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'app-global-messages',
  standalone: true,
  imports: [MatIcon, NgClass, MatIconButton],
  templateUrl: './global-messages.component.html',
  styleUrl: './global-messages.component.scss',
})
export class GlobalMessagesComponent implements OnInit {
  type: GlobalMessageType;
  message: string;
  showReloadBtn: boolean;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  ngOnInit() {
    if (this.data) {
      this.message = this.data.message;
      this.type = this.data.type;
      this.showReloadBtn = this.data.showReloadBtn;
    }
  }

  reload() {
    window.location.reload();
  }

  close() {
    this.data.dismiss();
  }
}
