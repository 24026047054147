<div class="wrap-dialog-content">
  <div class="d-flex align-items-center justify-content-between pb-3">
    <span class="dialog-title">Share Link</span>
    @if (!isLinkCopied) {
      <div class="copy-link-action d-flex align-items-center" (click)="copyLink()">
        <mat-icon>link</mat-icon>
        <span>Copy Link</span>
      </div>
    } @else {
      <div class="wrap-action-successful d-flex align-items-center">
        <div class="action-successful d-flex align-items-center">
          <span>Link copied</span>
          <mat-icon class="action-successful-icon">check_circle_outline</mat-icon>
        </div>
      </div>
    }
    @if (!showCancelBtn) {
      <button type="button" mat-icon-button class="close-btn" (click)="closeShareLinkDialog()">
        <mat-icon class="action-menu-icon">close</mat-icon>
      </button>
    }
  </div>
  @if (!isEmailSubmitted) {
    <div>
      <mat-form-field class="user-email" appearance="outline">
        <mat-label>Enter Target Email</mat-label>
        <input
          matInput
          #userEmail
          type="email"
          class="user-email-input"
          [formControl]="userEmailControl"
          (keydown.enter)="shareByEmailClick()" />
        <mat-icon
          (click)="shareByEmailClick()"
          class="send-link"
          [class.active]="userEmailControl.valid && userEmailControl.value"
          >send</mat-icon
        >
        @if (!userEmailControl.errors || !userEmailControl.value) {
          <mat-hint class="input-hint">This email will save the quote by default. Press Enter to send.</mat-hint>
        }
        @if (userEmailControl.errors) {
          <mat-error>Email format does not match.</mat-error>
        }
      </mat-form-field>
    </div>
  } @else {
    <div class="wrap-action-successful d-flex align-items-center pt-2">
      <div class="action-successful big d-flex align-items-center justify-content-between">
        <span>Email was sent!</span>
        <mat-icon class="action-successful-icon">check_circle_outline</mat-icon>
      </div>
    </div>
  }
  @if (isInProgress) {
    <div class="wrap-small-spinner d-flex align-items-center justify-content-center">
      <mat-spinner [diameter]="40" [value]="70"></mat-spinner>
    </div>
  }
  @if (showCancelBtn) {
    <div class="d-flex justify-content-end">
      <button mat-button color="primary" class="btn-sm" (click)="closeShareLinkDialog()">Cancel</button>
    </div>
  }
</div>
