import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationGridResponse } from '../models/quotes.interface';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QuotesRepository {
  constructor(private http: HttpClient) {}

  getSharedLink() {
    const url = `${environment.apiUrl}/api/v1/Application/GetLinkToShare`;
    return this.http.get(url, { params: { skipLoader: true }, responseType: 'text' });
  }

  shareLinkByEmail(email: string, listbill: string) {
    const url = `${environment.apiUrl}/api/v1/Application/ShareUniversalLinkByMail`;
    return this.http.get(url, { params: { email: email, listBillId: listbill, skipLoader: true } });
  }

  sendApplicationQuoteLinkByMail(applicationId: string) {
    const url = `${environment.apiUrl}/api/v1/Application/SendApplicationQuoteLinkByMail`;
    return this.http.get(url, { params: { applicationId: applicationId, skipLoader: true } });
  }

  resendApplicationLinkByMail(applicationId: string) {
    const url = `${environment.apiUrl}/api/v1/Application/ResendApplicationLinkByMail`;
    return this.http.get(url, { params: { applicationId: applicationId, skipLoader: true } });
  }

  getApplicationData(pageParams: HttpParams): Observable<ApplicationGridResponse> {
    const url = `${environment.apiUrl}/api/v1/Application/GetApplicationPage`;
    return this.http.get<ApplicationGridResponse>(url, { params: pageParams });
  }

  getLinkToLoomis() {
    const url = `${environment.apiUrl}/api/v1/Application/GetLinkToLoomis`;
    return this.http.get(url, { params: { skipLoader: true }, responseType: 'text' });
  }
}
