<div class="page-content-container h-100 mt-4 wrap-spinner">
  <div class="top-content">
    <div class="w-100">
      <div class="d-flex justify-content-between align-items-center pb-2">
        <span class="sub-title">Applications</span>
        <div class="d-flex section-actions">
          <a mat-stroked-button class="btn-lg create-btn" href="{{ agentModeLink }}" target="_blank">
            <span class="external-link d-flex align-items-center">
              <mat-icon>open_in_new</mat-icon>
              <span>Create New Quote</span>
            </span>
          </a>
          <button
            mat-flat-button
            color="primary"
            class="btn-lg"
            type="button"
            [class.faded]="isShowShareLinkDialog"
            (click)="shareLinkClick()">
            <span class="expand-btn d-flex align-items-center">
              Share Link
              @if (isShowShareLinkDialog) {
                <mat-icon>expand_less</mat-icon>
              } @else {
                <mat-icon class="mat-icon-lg-view">expand_more</mat-icon>
                <mat-icon class="mat-icon-md-view">expand_less</mat-icon>
              }
            </span>
          </button>
          @if (isShowShareLinkDialog && shareLink) {
            <app-share-quote-dialog
              [shareLinkValue]="shareLink"
              (closeShareQuoteDialog)="closeShareQuoteDialog()"></app-share-quote-dialog>
          }
        </div>
      </div>
    </div>
  </div>

  <div class="main-content">
    <div class="scrollable-area">
      <div class="w-100">
        <div class="section-filters">
          <nav mat-tab-nav-bar [tabPanel]="tabPanel">
            @for (tab of filterTabs; track tab.label) {
              <a mat-tab-link (click)="applyFilter(tab)" [active]="activeTab === tab">
                <mat-icon>{{ tab.icon }}</mat-icon>
                <span class="tab-label">{{ tab.label }}</span>
              </a>
            }
          </nav>
        </div>
        <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
      </div>

      <div class="w-100">
        <div class="separating-line" appFullWidthScroll [scrollableElement]="scrollableElement"></div>
        <div class="search-wrapper">
          <app-search #search></app-search>
        </div>

        <div #scrollableElement>
          <table mat-table [dataSource]="dataSource" matSort matSortDirection="desc" class="app-table">
            <ng-container matColumnDef="policyHolder">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Policy Holder Name / Email</th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex flex-column">
                  <span>{{ element.customerName || '--' }}</span>
                  <span class="user-email">{{ element.customerEmail || '--' }}</span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="group">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Group</th>
              <td mat-cell *matCellDef="let element">
                {{ element.listBillGroupName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
              <td mat-cell *matCellDef="let element">{{ element.state }}</td>
            </ng-container>

            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
              <td mat-cell *matCellDef="let element">{{ element.startDate | date: 'MM/dd/yyyy' }}</td>
            </ng-container>

            <ng-container matColumnDef="submittedDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Submitted</th>
              <td mat-cell *matCellDef="let element">{{ element.submissionDate | date: 'MM/dd/yyyy' }}</td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
              <td mat-cell *matCellDef="let element">
                <app-status [status]="element.statusType"></app-status>
              </td>
            </ng-container>

            <ng-container matColumnDef="progress">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Progress</th>
              <td mat-cell *matCellDef="let element">
                <div class="quote-progress d-flex align-items-center">
                  <mat-progress-bar mode="determinate" [value]="element.progress"></mat-progress-bar>
                  <span class="progress-label">{{ element.progress }}%</span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="payment">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment</th>
              <td mat-cell *matCellDef="let element">
                @if (!element.paymentType) {
                  <span>---</span>
                } @else {
                  <span>{{ paymentTypeLabel[element.paymentType] }}</span>
                }
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
              <td mat-cell *matCellDef="let element">
                <button type="button" class="action-menu-btn" mat-icon-button [matMenuTriggerFor]="action_menu">
                  <mat-icon class="action-menu-icon">more_horizontal</mat-icon>
                </button>

                <mat-menu #action_menu="matMenu" xPosition="before">
                  <button mat-menu-item (click)="resendLink(element)">
                    <span>Send link</span>
                  </button>
                  <button mat-menu-item (click)="openQuote(element)">
                    <span>Open quote</span>
                  </button>
                </mat-menu>
              </td>
            </ng-container>

            <tr class="mat-row row-no-data" *matNoDataRow>
              <td class="mat-cell cell-no-data" [attr.colspan]="displayedColumns.length">
                <div class="d-flex flex-column wrap-cell-no-data">
                  @if (!isInProgress) {
                    <img class="no-data-img" src="assets/img/no_data.png" alt="no-data" />
                    @if (searchComponent?.searchControl?.value) {
                      <span class="no-results-title">No results found</span>
                      <span class="no-results-subtitle"
                        >Try to adjust your search or filter options to find what you are looking for.</span
                      >
                    } @else {
                      <span class="no-results-title">There are no records yet</span>
                      <span class="no-results-subtitle">Wait until quotes will be populated by email.</span>
                    }
                  }
                </div>
              </td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-row"></tr>
          </table>
        </div>
      </div>
    </div>

    <div class="w-100">
      <div class="pagination-container d-flex align-items-center justify-content-end">
        <mat-paginator
          #paginator
          class="app-paginator"
          [pageSizeOptions]="pageSizeOptions"
          [length]="quotesTotalCount"
          [pageIndex]="pageParams.PageIndex"
          [showFirstLastButtons]="true"
          (page)="onPaginatorChange($event)">
        </mat-paginator>
      </div>
    </div>
  </div>

  @if (isInProgress) {
    <div class="spinner d-flex align-items-center justify-content-center w-100">
      <mat-spinner [diameter]="40" [value]="70"></mat-spinner>
    </div>
  }
</div>

@if (isShowShareLinkDialog) {
  <div class="overlay-dialog"></div>
}
