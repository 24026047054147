import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AgentsUploadSummary } from '../models/admin.interface';
import { environment } from '../../../environments/environment';
import { AgentsGridResponse } from '../models/agents.interface';

@Injectable({
  providedIn: 'root',
})
export class AgentsRepository {
  constructor(private http: HttpClient) {}

  getTemplateDownloadLink() {
    const url = `${environment.apiUrl}/api/v1/Agent/GetTemplateDownloadLink`;
    return this.http.get(url, { responseType: 'text' });
  }

  uploadAgentsList(file): Observable<AgentsUploadSummary> {
    const url = `${environment.apiUrl}/api/v1/Agent/uploadAgentList`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('FileName', file.name);
    return this.http.post<AgentsUploadSummary>(url, formData, {
      headers: { Accept: 'text/plain', enctype: 'multipart/form-data' },
    });
  }

  getAgentsData(pageParams: HttpParams): Observable<AgentsGridResponse> {
    const url = `${environment.apiUrl}/api/v1/Agent/getAgentPage`;
    return this.http.get<AgentsGridResponse>(url, { params: pageParams });
  }

  getDistributions() {
    const url = `${environment.apiUrl}/api/v1/Distribution`;
    return this.http.get(url);
  }

  createEditAgent(data) {
    const url = `${environment.apiUrl}/api/v1/Agent`;
    return this.http.post(url, data);
  }
}
